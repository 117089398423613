<template>
  <div class="container">
    <Breadcrumb v-if="course">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'courseIntrosList' }">
            {{ $t("pageTitle.courseIntrosList") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'CoursesIntroDetail',
              query: { course_id: this.$route.query.course_id }
            }"
          >
            {{ helper.displayI18nText($i18n.locale, course.title) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          梯次清單
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Breadcrumb v-else>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("pageTitle.courseSessionsList") }} - 梯次清單
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div v-if="course">
      <div>
        <h3>
          {{ helper.displayI18nText($i18n.locale, course.title) }} - 梯次清單
          <el-tooltip
            class="item"
            effect="dark"
            content="創建梯次"
            placement="top"
            v-show="isActive"
          >
            <router-link :to="{ name: 'courseSessionsCreate' }">
              <i class="fas fa-plus action-icon" />
            </router-link>
          </el-tooltip>
        </h3>
      </div>
      <hr />
    </div>
    <div v-else>
      <h3>
        <span
          >{{ $t("pageTitle.courseSessionsList") }}
          <router-link :to="{ name: 'courseSessionsCreate' }">
            <i class="fas fa-plus action-icon" />
          </router-link>
        </span>
      </h3>
      <hr />
    </div>
    <div>
      <el-radio-group v-model="activeName">
        <el-radio-button label="activeList">Ongoing</el-radio-button>
        <el-radio-button label="inActiveList">Inactive</el-radio-button>
      </el-radio-group>
      <div>
        <el-table
          :data="
            activeName === 'activeList'
              ? pagedCourseSessionsList
              : pagedInactiveCourseSessionsList
          "
          style="width: 100%;margin: 0 0 20px"
        >
          <el-table-column label="所屬課程" width="200px">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.course != null"
                :to="{
                  name: 'courseSessionsDetail',
                  params: { id: scope.row.id }
                }"
              >
                {{
                  helper.displayI18nText($i18n.locale, scope.row.course.title)
                }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="梯次標題">
            <template slot-scope="scope">{{
              helper.displayI18nText($i18n.locale, scope.row.title)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="short_name"
            :label="$t('courseSessions.short_name')"
          >
          </el-table-column>
          <el-table-column prop="city" label="上課城市"></el-table-column>
          <el-table-column label="是否是線上課">
            <template slot-scope="scope">
              <IsOnlineIcon :isOnline="scope.row.is_online" />
            </template>
          </el-table-column>
          <el-table-column label="類型" width="150px">
            <template slot-scope="scope">
              <div v-if="scope.row.type === 2">
                <el-tag type="warning">Private 家教課</el-tag>
              </div>
              <div v-else-if="scope.row.type === 3">
                <el-tag type="danger">Mentor 升學課</el-tag>
              </div>
              <div v-else-if="scope.row.type === 4">
                <el-tag type="info">Custom 客製</el-tag>
              </div>
              <div v-else>
                <el-tag type="success">Group 團體課</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="日期">
            <template slot-scope="scope">
              {{ scope.row.started_on }} <br />
              <span v-if="scope.row.ended_on">
                ~
                {{ scope.row.ended_on }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="班級" width="170px">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'sessionClassesList',
                  query: { course_session_id: scope.row.id }
                }"
              >
                <el-button size="mini" type="warning">
                  <i class="el-icon-s-grid"></i>
                  <b> {{ scope.row.session_classes_count }}</b>
                  個班級
                </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="200px">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'courseSessionsEdit',
                  params: { id: scope.row.id }
                }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="編輯梯次"
                  placement="top"
                >
                  <el-button type="primary" size="mini" class="mr-2">
                    <i class="fas fa-edit" />
                  </el-button>
                </el-tooltip>
              </router-link>
              <el-tooltip
                class="item"
                effect="dark"
                content="暫停梯次"
                placement="top"
                v-show="isActive"
              >
                <el-button
                  type="primary"
                  size="mini"
                  @click="inactiveSession(scope.row.id, scope.row)"
                >
                  <i class="far fa-stop-circle" />
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="繼續梯次"
                placement="top"
                v-show="!isActive"
              >
                <el-button
                  type="primary"
                  size="mini"
                  @click="activeSession(scope.row.id, scope.row)"
                >
                  <i class="far fa-play-circle" />
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="刪除梯次"
                placement="top"
              >
                <el-button
                  type="danger"
                  size="mini"
                  @click="handleDelete(scope.row.id)"
                >
                  <i class="far fa-trash-alt" />
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center">
          <el-pagination
            :hide-on-single-page="true"
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="isActive ? currentPage : inactiveCurrentPage"
            @current-change="handleCurrentPageChange"
            :total="isActive ? sessionList.length : inactiveSessionList.length"
          >
          </el-pagination>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";

export default {
  metaInfo() {
    return {
      title: "Course Sessions - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb,
    IsOnlineIcon
  },

  props: [],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      sessionList: [],
      pagedCourseSessionsList: [],

      inactiveCurrentPage: 1,
      inactiveSessionList: [],
      pagedInactiveCourseSessionsList: [],

      course: null,
      activeName: "activeList"
    };
  },
  computed: {
    helper() {
      return helper;
    },
    isActive() {
      return this.activeName === "activeList";
    }
  },
  watch: {
    activeName() {
      this.initialSessionList();
    }
  },

  async created() {
    if (this.$route.query.activeTab) {
      this.activeName = this.$route.query.activeTab;
    }

    if (this.$route.query.activePage) {
      this.currentPage = Number(this.$route.query.activePage);
    }
    if (this.$route.query.inactivePage) {
      this.inactiveCurrentPage = Number(this.$route.query.inactivePage);
    }
    await this.initialSessionList();
  },

  methods: {
    async initialSessionList() {
      await this.loadCourse();
      if (this.activeName === "inActiveList") {
        await this.loadInactiveCourseSession();
      } else {
        await this.loadCourseSession();
      }
      this.handleCurrentChange();
      this.handleInactiveCurrentChange(this.inactiveCurrentPage);
    },
    async loadCourse() {
      if (this.$route.query.course_id) {
        let courseRes = await courseApi.fetchCourse(
          this.$route.query.course_id
        );
        this.course = courseRes.course;
      }
    },
    async loadCourseSession() {
      let queryParams = {
        course_id: this.$route.query.course_id,
        is_visible: 1
      };
      let sessionRes = await courseApi.fetchSessions(queryParams);
      this.sessionList = sessionRes.course_sessions;
    },
    async loadInactiveCourseSession() {
      let queryParams = {
        course_id: this.$route.query.course_id,
        is_visible: 0
      };
      let sessionRes = await courseApi.fetchSessions(queryParams);
      this.inactiveSessionList = sessionRes.course_sessions;
    },
    handleQueryChange(queries) {
      let routerQueries = { ...this.$route.query, ...queries };
      this.$router.push({
        name: "courseSessionsList",
        query: routerQueries
      });
    },
    handleCurrentPageChange(page) {
      if (this.activeName === "activeList") {
        this.handleQueryChange({ activePage: page });
      } else {
        this.handleQueryChange({ inactivePage: page });
      }
    },
    handleCurrentChange() {
      if (this.activeName === "activeList") {
        this.currentChangePage(this.sessionList, this.currentPage);
      } else {
        this.handleInactiveCurrentChange(this.inactiveCurrentPage);
      }
    },
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.pagedCourseSessionsList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.pagedCourseSessionsList.push(list[from]);
        }
      }
    },

    handleInactiveCurrentChange(currentPage) {
      this.inactiveCurrentPage = currentPage;
      this.inactiveCurrentChangePage(this.inactiveSessionList, currentPage);
    },
    inactiveCurrentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.pagedInactiveCourseSessionsList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.pagedInactiveCourseSessionsList.push(list[from]);
        }
      }
    },

    createCourseSession() {
      this.$router.push({
        name: "courseSessionsCreate",
        query: {
          course_id: this.course ? this.course.id : 0
        }
      });
    },

    handleDelete(id) {
      this.$confirm(
        this.$t("courses.delete_alert"),
        this.$t("courses.delete"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await courseApi.deleteSession(id);
          this.$message({
            type: "info",
            message: this.$t("newCourses.Deleted successfully")
          });
          await this.initialSessionList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.cancel")
          });
        });
      // await this.loadCourseSession();
      // await this.loadCourse();
      // await this.loadCourseSession();
      // await this.loadInactiveCourseSession();
      // this.handleCurrentChange();
      // this.handleInactiveCurrentChange(this.currentPage);
    },
    async inactiveSession(id, session) {
      try {
        await courseApi.postSession(id, { ...session, is_visible: 0 });
        await this.initialSessionList();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async activeSession(id, session) {
      try {
        await courseApi.postSession(id, { ...session, is_visible: 1 });
        await this.initialSessionList();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped></style>
