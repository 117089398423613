var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.course)?_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'courseIntrosList' }}},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.courseIntrosList"))+" ")])],1),_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'CoursesIntroDetail',
            query: { course_id: this.$route.query.course_id }
          }}},[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.course.title))+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" 梯次清單 ")])],1)],2):_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.courseSessionsList"))+" - 梯次清單 ")])],1)],2),(_vm.course)?_c('div',[_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.course.title))+" - 梯次清單 "),_c('el-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"item",attrs:{"effect":"dark","content":"創建梯次","placement":"top"}},[_c('router-link',{attrs:{"to":{ name: 'courseSessionsCreate' }}},[_c('i',{staticClass:"fas fa-plus action-icon"})])],1)],1)]),_c('hr')]):_c('div',[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.$t("pageTitle.courseSessionsList"))+" "),_c('router-link',{attrs:{"to":{ name: 'courseSessionsCreate' }}},[_c('i',{staticClass:"fas fa-plus action-icon"})])],1)]),_c('hr')]),_c('div',[_c('el-radio-group',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-radio-button',{attrs:{"label":"activeList"}},[_vm._v("Ongoing")]),_c('el-radio-button',{attrs:{"label":"inActiveList"}},[_vm._v("Inactive")])],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%","margin":"0 0 20px"},attrs:{"data":_vm.activeName === 'activeList'
            ? _vm.pagedCourseSessionsList
            : _vm.pagedInactiveCourseSessionsList}},[_c('el-table-column',{attrs:{"label":"所屬課程","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.course != null)?_c('router-link',{attrs:{"to":{
                name: 'courseSessionsDetail',
                params: { id: scope.row.id }
              }}},[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, scope.row.course.title))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"梯次標題"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, scope.row.title)))]}}])}),_c('el-table-column',{attrs:{"prop":"short_name","label":_vm.$t('courseSessions.short_name')}}),_c('el-table-column',{attrs:{"prop":"city","label":"上課城市"}}),_c('el-table-column',{attrs:{"label":"是否是線上課"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('IsOnlineIcon',{attrs:{"isOnline":scope.row.is_online}})]}}])}),_c('el-table-column',{attrs:{"label":"類型","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 2)?_c('div',[_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("Private 家教課")])],1):(scope.row.type === 3)?_c('div',[_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("Mentor 升學課")])],1):(scope.row.type === 4)?_c('div',[_c('el-tag',{attrs:{"type":"info"}},[_vm._v("Custom 客製")])],1):_c('div',[_c('el-tag',{attrs:{"type":"success"}},[_vm._v("Group 團體課")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"日期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.started_on)+" "),_c('br'),(scope.row.ended_on)?_c('span',[_vm._v(" ~ "+_vm._s(scope.row.ended_on)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"班級","width":"170px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'sessionClassesList',
                query: { course_session_id: scope.row.id }
              }}},[_c('el-button',{attrs:{"size":"mini","type":"warning"}},[_c('i',{staticClass:"el-icon-s-grid"}),_c('b',[_vm._v(" "+_vm._s(scope.row.session_classes_count))]),_vm._v(" 個班級 ")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'courseSessionsEdit',
                params: { id: scope.row.id }
              }}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"編輯梯次","placement":"top"}},[_c('el-button',{staticClass:"mr-2",attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fas fa-edit"})])],1)],1),_c('el-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"item",attrs:{"effect":"dark","content":"暫停梯次","placement":"top"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.inactiveSession(scope.row.id, scope.row)}}},[_c('i',{staticClass:"far fa-stop-circle"})])],1),_c('el-tooltip',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isActive),expression:"!isActive"}],staticClass:"item",attrs:{"effect":"dark","content":"繼續梯次","placement":"top"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.activeSession(scope.row.id, scope.row)}}},[_c('i',{staticClass:"far fa-play-circle"})])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"刪除梯次","placement":"top"}},[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.handleDelete(scope.row.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])],1)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"hide-on-single-page":true,"background":"","layout":"prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.isActive ? _vm.currentPage : _vm.inactiveCurrentPage,"total":_vm.isActive ? _vm.sessionList.length : _vm.inactiveSessionList.length},on:{"current-change":_vm.handleCurrentPageChange}})],1)],1),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }